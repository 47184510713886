import React from "react";

export default function Footer() {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer className="bg-black text-white py-6">
      <div className="text-center">
        <p>&copy; {currentYear} FonSwitch, Inc. All rights reserved.</p>
      </div>
    </footer>
  );
}
