import { useState } from "react";

export default function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [honeypot, setHoneypot] = useState(""); // Hidden honeypot field
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState(""); // To hold email error message

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if honeypot field is filled (indicating spam)
    if (honeypot) {
      setErrorMessage("Spam detected.");
      return;
    }

    // Check if email is valid
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailErrorMessage("Please enter a valid email address.");
      return;
    } else {
      setEmailErrorMessage(""); // Clear the email error message if valid
    }

    setIsSubmitting(true);

    const formData = {
      name,
      email,
      message,
      honeypot, // Still checking honeypot for spam bots
    };

    try {
      const response = await fetch("/send-email.php", { // Send data to PHP script
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Ensure this is set correctly for JSON data
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json(); // Assuming PHP returns a JSON response

      if (response.ok) {
        setSuccessMessage("Your message was sent successfully!");
        setName("");
        setEmail("");
        setMessage("");
      } else {
        setErrorMessage(data.message || "There was an error sending your message.");
      }
    } catch (error) {
      console.error("Network error:", error);
      setErrorMessage("Network error. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col items-center px-6">
      <div className="bg-gray-800 p-8 rounded-2xl shadow-lg w-full max-w-md text-white">
        <h1 className="text-3xl font-semibold text-center mb-6">Contact Us</h1>

        {successMessage && (
          <div className="text-green-500 text-center mb-4 p-3 bg-green-800 rounded-lg">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="text-red-500 text-center mb-4 p-3 bg-red-800 rounded-lg">
            {errorMessage}
          </div>
        )}
        {emailErrorMessage && (
          <div className="text-red-500 text-center mb-4 p-3 bg-red-800 rounded-lg">
            {emailErrorMessage}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Honeypot hidden field */}
          <input
            type="text"
            name="honeypot"
            value={honeypot}
            onChange={(e) => setHoneypot(e.target.value)}
            style={{ display: "none" }}
            aria-hidden="true"
            tabIndex="-1"
          />

          <label htmlFor="name" className="sr-only">Name</label>
          <input
            id="name"
            type="text"
            placeholder="Name"
            className="w-full p-3 rounded-lg bg-gray-700 text-white border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <label htmlFor="email" className="sr-only">Email</label>
          <input
            id="email"
            type="email"
            placeholder="Email"
            className="w-full p-3 rounded-lg bg-gray-700 text-white border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="message" className="sr-only">Message</label>
          <textarea
            id="message"
            placeholder="Your Message"
            className="w-full p-3 h-32 rounded-lg bg-gray-700 text-white border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none resize-none"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>

          {isSubmitting && <div className="spinner-border text-white" role="status"></div>}

          <button
            type="submit"
            className="w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-500 transition disabled:bg-gray-600"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Sending..." : "Send Message"}
          </button>
        </form>
      </div>
    </div>
  );
}
