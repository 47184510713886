import React from "react";
import { motion } from "framer-motion";

export default function AboutPage() {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col items-center px-6"> {/* Added padding here */}
      {/* Hero Section */}
      <section className="text-center py-20 max-w-4xl mx-auto">
        <motion.h1
          className="text-4xl font-bold mb-4 text-gray-100"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          About FonSwitch
        </motion.h1>
        <p className="text-lg text-gray-300 mb-6 text-left">
          <br /><br />FonSwitch has been at the forefront of providing innovative and reliable solutions since 2008. Originally founded as a VoIP Service Provider, we started by offering open-source telecom and Linux architecture consulting services. Over the years, our reach has grown, and we have diversified our offerings to meet the evolving needs of our diverse client base, which includes government agencies, private companies, healthcare organizations, and local governments.<br />
          <br />
          We specialize in mission-critical and sensitive environments, where operational success is paramount. Our team consists of subject matter experts who bring a wealth of experience in delivering secure, efficient, and scalable solutions tailored to meet the unique challenges faced by each sector.<br />
          <br />
          At FonSwitch, we understand the intricacies of working in regulated industries and high-stakes scenarios, and our expanded service offerings now reflect this. From secure communication systems to data management and infrastructure consulting, we are equipped to support the objectives of a wide range of organizations. Whether you're in healthcare, government, or the private sector, FonSwitch is committed to driving the success of your mission.<br />
          <br />
          We are proud to be a registered entity with SAM (System for Award Management), holding a valid UEI and CAGE code, and are fully capable of supporting both federal and state-level projects. Our team’s versatility and adaptability ensure that we can meet the needs of all our clients, no matter their size or scope.
        </p>
      </section>
    </div>
  );
}
