import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaShieldAlt, FaGlobeAmericas, FaServer, FaStethoscope, FaBuilding, FaLightbulb } from 'react-icons/fa';

const ServiceCard = ({ service }) => {
  return (
    <div className="service-card bg-gray-800 text-white rounded-lg p-6 hover:shadow-lg transition-all duration-300">
      <div className="icon text-3xl mb-4">{service.icon}</div>
      <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
      <p>{service.shortDescription}</p>
      <Link to={service.url} className="text-blue-400 hover:underline mt-4 block">Learn More</Link>
    </div>
  );
};

const ServicesPage = () => {
  const [search, setSearch] = useState('');

  const services = [
    {
      title: "Healthcare Consulting",
      shortDescription: "Expert solutions to enhance patient care and operational efficiency in healthcare organizations, clinics, and facilities.",
      url: "/healthcare-consulting",
      icon: <FaStethoscope />,
    },
    {
      title: "Cyber & Infrastructure Security",
      shortDescription: "State-of-the-art security solutions for safeguarding healthcare systems, government agencies, and businesses from evolving cyber threats.",
      url: "/cyber-security",
      icon: <FaShieldAlt />,
    },
    {
      title: "Global Technology Operations",
      shortDescription: "Optimizing technology infrastructure for seamless operations across global sectors, including healthcare, defense, and government.",
      url: "/technology",
      icon: <FaGlobeAmericas />,
    },
    {
      title: "Asset & Property Management",
      shortDescription: "Comprehensive management strategies for real estate investors, government facilities, and businesses, ensuring long-term value and efficiency.",
      url: "/asset-management",
      icon: <FaBuilding />,
    },
    {
      title: "Mission Support & Logistics",
      shortDescription: "Delivering mission-critical logistics and operational support for healthcare, defense, and government sectors, ensuring business continuity.",
      url: "/business-continuity",
      icon: <FaServer />,
    },
    {
      title: "Custom Solutions & Rapid Prototyping",
      shortDescription: "Developing tailored solutions and prototypes to meet the unique challenges of the healthcare, defense, and technology sectors.",
      url: "/consulting",
      icon: <FaLightbulb />,
    },
    {
      title: "Logistics & Supply Chain Resilience",
      shortDescription: "Strengthening logistics and supply chain operations for businesses, government agencies, and healthcare delivery systems to ensure resilience.",
      url: "/logistics",
      icon: <FaServer />,
    },
    {
      title: "Government & Defense Operations",
      shortDescription: "Providing critical support to government and defense agencies through secure communication systems, logistics, and IT infrastructure.",
      url: "/defense-operations",
      icon: <FaShieldAlt />,
    },
    {
      title: "Advanced Data & Intelligence Solutions",
      shortDescription: "Leveraging cutting-edge data analysis and intelligence tools to drive decision-making in healthcare, defense, and government sectors.",
      url: "/data-intelligence",
      icon: <FaGlobeAmericas />,
    },
    {
      title: "IT & Telecom Infrastructure",
      shortDescription: "Optimizing IT and telecom infrastructure to ensure secure, efficient operations for healthcare, businesses, and government sectors.",
      url: "/it-telecom",
      icon: <FaServer />,
    },
    {
      title: "Property Management",
      shortDescription: "Providing strategic oversight and management of real estate properties for investors and government facilities to maximize returns.",
      url: "/property-management",
      icon: <FaBuilding />,
    },
    {
      title: "Asset Management",
      shortDescription: "Comprehensive asset management solutions to ensure operational efficiency and longevity for businesses and government agencies.",
      url: "/asset-management",
      icon: <FaBuilding />,
    },
  ];

  const filteredServices = services.filter(service =>
    service.title.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col px-6 py-20">
      {/* Sticky Navbar */}
      <nav className="sticky top-0 bg-gray-800 p-4">
        <ul className="flex space-x-6 justify-center">
          <li><Link to="/services" className="text-lg text-blue-400 hover:underline">All Services</Link></li>
          <li><Link to="/healthcare" className="text-lg text-blue-400 hover:underline">Healthcare</Link></li>
          <li><Link to="/technology" className="text-lg text-blue-400 hover:underline">Technology</Link></li>
          <li><Link to="/asset-management" className="text-lg text-blue-400 hover:underline">Asset Management</Link></li>
          <li><Link to="/logistics" className="text-lg text-blue-400 hover:underline">Logistics</Link></li>
          <li><Link to="/defense-operations" className="text-lg text-blue-400 hover:underline">Government & Defense</Link></li>
        </ul>
      </nav>

      {/* Main Content */}
      <section className="text-center mb-12">
        <h2 className="text-4xl font-bold mb-4">Our Services</h2>
        <input
          type="text"
          placeholder="Search Services..."
          value={search}
          onChange={e => setSearch(e.target.value)}
          className="text-black p-2 rounded-md mb-6 w-full md:w-1/2 lg:w-1/3 mx-auto"
        />

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredServices.length ? filteredServices.map(service => (
            <ServiceCard key={service.title} service={service} />
          )) : <p>No services found.</p>}
        </div>
      </section>

      {/* Service Highlights */}
      <section className="bg-gray-800 rounded-lg p-6 mb-12">
        <h2 className="text-3xl font-bold text-center mb-6">Service Highlights</h2>

        <div className="space-y-4">
          {/* Healthcare Consulting Accordion */}
          <div className="bg-gray-700 p-4 rounded-lg">
            <h3 className="text-2xl font-semibold">Healthcare Consulting</h3>
            <p className="mt-2">Optimizing healthcare organizations by enhancing patient care and operational efficiency, with a focus on integrating technology and improving healthcare delivery.</p>
            <Link to="/healthcare-consulting" className="text-blue-400 hover:underline mt-4 block">Learn More</Link>
          </div>

          {/* Cyber Security Accordion */}
          <div className="bg-gray-700 p-4 rounded-lg">
            <h3 className="text-2xl font-semibold">Cyber & Infrastructure Security</h3>
            <p className="mt-2">Protecting critical healthcare and government systems from cyber threats through cutting-edge security protocols and infrastructure management solutions.</p>
            <Link to="/cyber-security" className="text-blue-400 hover:underline mt-4 block">Learn More</Link>
          </div>
          
          {/* Repeat the accordion pattern for other services */}
        </div>
      </section>
    </div>
  );
};

export default ServicesPage;
