import React from 'react';
import ReactDOM from 'react-dom/client'; // React 18+
import './index.css'; // or './App.css'
import App from './App'; // your main component

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />  // No Router here
);

