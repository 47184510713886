export default function NotFoundPage() {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col items-center justify-center">
      <h1 className="text-4xl font-semibold mb-4">404 - Page Not Found</h1>
      <p className="text-lg text-gray-300 mb-6">
        Oops! The page you're looking for doesn't exist.
      </p>
      <a href="/" className="text-blue-500 hover:underline">Go back to Home</a>
    </div>
  );
}
