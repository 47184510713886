import React, { useState } from "react";
import { Button } from "../components/ui/button";
import { Card, CardContent } from '@mui/material'; // If using Material-UI
import { motion } from "framer-motion";


// Importing icons from lucide-react
import { ShieldCheck, Globe, Server, Stethoscope, Building2, Lightbulb } from 'lucide-react';

export default function HomePage() {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  // Define services with icons as React components
  const services = [
    { icon: ShieldCheck, title: "Cyber & Infrastructure Security", text: "Protecting networks, assets, and mission-critical operations from evolving threats.", color: "text-blue-400", url: '/cyber-security' },
    { icon: Globe, title: "Global Technology Operations", text: "Optimizing infrastructure, managed services, and telecom architecture for seamless mission execution.", color: "text-green-400", url: '/technology' },
    { icon: Server, title: "Mission Support & Logistics", text: "Delivering innovative solutions in supply chain resilience, infrastructure hardening, and tactical support.", color: "text-yellow-400", url: '/business-continuity' },
    { icon: Stethoscope, title: "Healthcare Consulting", text: "Advancing healthcare operations, education, and IT to improve patient outcomes and efficiency.", color: "text-red-400", url: '/healthcare' },
    { icon: Building2, title: "Asset & Property Management", text: "Strategic asset management and property oversight to maximize operational efficiency and value.", color: "text-purple-400", url: '/asset-management' },
    { icon: Lightbulb, title: "Custom Solutions & Rapid Prototyping", text: "Developing tailored solutions to meet unique mission requirements with speed and precision.", color: "text-lime-400", url: '/consulting' }
  ];


  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col items-center justify-center px-6">

      {/* Hero Section */}
      <section className="text-center py-20 max-w-4xl bg-cover bg-center" style={{ backgroundImage: 'url(/components/images/image1.jpg)' }}>
        <motion.h1
          className="text-4xl font-bold mb-4 text-gray-100"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          Securing the Future. Empowering the Mission.
        </motion.h1>
        <p className="text-lg text-gray-300 mb-6">
          FonSwitch provides cutting-edge solutions in cybersecurity, technology operations, healthcare consulting, and asset management to drive operational success.
        </p>
        <button className="bg-blue-600 hover:bg-blue-500 px-6 py-3 text-lg text-white" onClick={() => window.location.href = '/services'} > Learn More </button>
      </section>


      {/* Capabilities Section */}
      <section className="grid md:grid-cols-3 gap-6 mt-12 max-w-5xl mx-auto">
        {services.map((item, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.05 }}
            onClick={() => setSelectedService(serviceDetails[item.serviceKey])}
            className="transition-colors duration-300 cursor-pointer"
          >

            <Card className="bg-gray-300 border-gray-600 hover:bg-gray-700 h-full" onClick={() => window.location.href = item.url}>
              <CardContent className="bg-gray-800  hover:bg-gray-700 h-full p-6 flex flex-col items-center text-center">
                <item.icon className={`${item.color} w-12 h-12 mb-4`} />
                <h2 className="text-xl font-semibold text-gray-100">{item.title}</h2>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </section>


      {/* Modal */}
      {modalVisible && selectedService && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg w-1/3">
            <h2 className="text-2xl font-bold mb-4">{selectedService.title}</h2>
            <p>{selectedService.text}</p>
            <Button onClick={() => setModalVisible(false)} className="mt-4 bg-blue-600 hover:bg-blue-500">
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

