import { useState } from "react";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <header className="bg-gray-900 text-white py-4 shadow-lg">
      <nav className="container mx-auto flex justify-between items-center">
        {/* Logo Section */}
        <a href="/" className="flex items-center">
          <img
            src="/images/logo.png"
            alt="FonSwitch | Serving technology, healthcare, infrastructure, and operational readiness"
            className="h-auto"
            style={{ maxWidth: '200px', width: '200px' }}
          />
        </a>

        {/* Hamburger Menu Button (Visible on small screens) */}
        <button
          onClick={toggleMenu}
          className="lg:hidden text-white focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Navigation Links for Small Screens */}
        <div className={`lg:hidden ${isOpen ? "block" : "hidden"} absolute top-16 left-0 right-0 bg-gray-800 text-white flex flex-col space-y-4 p-4`} px-6>
          <a href="/" className="hover:text-blue-500">Home</a>
          <a href="/about" className="hover:text-blue-500">About Us</a>
          <a href="/services" className="hover:text-blue-500">Services</a>
          <a href="/contact" className="hover:text-blue-500">Contact</a>
        </div>

        {/* Navigation Links for Large Screens */}
        <div className="lg:flex space-x-6 hidden">
          <a href="/" className="hover:text-blue-500">Home</a>
          <a href="/about" className="hover:text-blue-500">About Us</a>
          <a href="/services" className="hover:text-blue-500">Services</a>
          <a href="/contact" className="hover:text-blue-500">Contact</a>
        </div>
      </nav>
    </header>
  );
}
